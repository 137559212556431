import React, { Component } from "react";
import Header from "./components/Header";
import "../css/partner-with-us.css";
import HeroSection from "./components/partner-with-us/hero-section";
import PartnerReason from "./components/partner-with-us/partner-reason";
import CollabDetail from "./components/partner-with-us/collab-detail";
import PartnerResult from "./components/partner-with-us/partner-result";
import PartnerContactUs from "./components/partner-with-us/partner-contact-form";
import ConnectWithUsFloatingCta from "./components/partner-with-us/connect-cta-floating";
import Loader from "./components/Loader";
import axios from "axios";
import { unflatten } from "flat";
import C9 from "./components/C9";
const unflattenTemplateItems = (templateItems) => {
  let content = {};
  content = templateItems.reduce(
    (agg, cur) => ({ ...agg, [cur.key]: cur.value }),
    {}
  );
  content = unflatten(content);
  return content;
};

const makeAssetsByKey = (assets) => {
  let content = {};
  content = assets.reduce(
    (agg, cur) => ({ ...agg, [cur.key]: cur.value.data.attributes }),
    {}
  );
  return content;
};

const getWebsiteConfig = (config) => {
  let websiteConfig = {};
  config.map((item) => {
    websiteConfig = {
      ...websiteConfig,
      [item.attributes.page]: {
        assets: makeAssetsByKey(item.attributes.assets),
        config: unflattenTemplateItems(item.attributes.template_items),
      },
    };
  });
  return websiteConfig;
};
export default class PartnerWithUs extends Component {
  state = {
    websiteConfig: null,
  };
  componentDidMount() {
    try{
      document.getElementById("fc_frame").classList.add('display-none')
    }catch(err){
    }
    const endPoint = `${process.env.GATSBY_STRAPI_BASE_URL}/api/website-configs?populate=template_items&populate=assets.value`;
    axios
      .get(endPoint)
      .then((res) => {
        setTimeout(() => {
          this.setState({
            websiteConfig: getWebsiteConfig(res?.data?.data),
          });
        }, 2000);
      })
      .catch((err) => {});
  }
  componentDidUpdate(){
    try{
      document.getElementById("fc_frame").classList.add('display-none')
    }catch(err){
    }
  }
  render() {
    if (!this.state.websiteConfig) {
      return (
        <div>
          <Header
            overrideStyle={{
              container: {
                backgroundColor: "#8A27F7",
              },
            }}
          />
          <Loader />
        </div>
      );
    }
    return (
      <React.Fragment>
        <Header />
        <HeroSection
          data={this.state.websiteConfig?.home?.config?.partnerWithUs?.hero}
          assets={this.state.websiteConfig.home?.assets}
        />
        <PartnerReason
          data={this.state.websiteConfig?.home?.config?.partnerWithUs?.reason}
          assets={this.state.websiteConfig.home?.assets}
        />
        <CollabDetail
          data={
            this.state.websiteConfig?.home?.config?.partnerWithUs?.collabDetail
          }
          assets={this.state.websiteConfig.home?.assets}
        />
        <PartnerResult
          data={
            this.state.websiteConfig?.home?.config?.partnerWithUs?.collabResult
          }
          assets={this.state.websiteConfig.home?.assets}
        />
        <PartnerContactUs />
        <ConnectWithUsFloatingCta />
        <C9 />
      </React.Fragment>
    );
  }
}
